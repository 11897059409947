@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.my-card-container {
	text-align: center;
	padding: 20px;
	width: 100%;
  }
  
  .card-element-wrapper {
	display: inline-block;
	width: 100%;
  }
  @media only screen and (max-width: 600px) {
	.CardField-input-wrapper {
	  flex-direction: column;
	}
	.CardField-number, .CardField-expiry, .CardField-cvc, .CardField-postalCode {
	  width: 100%;
	  transform: translateX(0) !important;
	}
  }
  
@keyframes float {
	0% {
	  transform: translatey(0px);
	}
	50% {
	  transform: translatey(-10px);
	}
	100% {
	  transform: translatey(0px);
	}
  }
  
  .float-effect {
	animation: float 2s ease-in-out infinite;
  }
  
@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

.bouncing-ball {
	display: inline-block;
	width: 40px;
	height: 40px;
	background-color: #57AF63;
	border-radius: 50%;
	animation: bounce 1s infinite;
  }
  
  
  @keyframes bounce {
	0%, 100% {
	  transform: translateY(0);
	}
	50% {
	  transform: translateY(-50px);
	}
  }

  .bouncing-ballX {
	display: inline-block;
	width: 40px;
	height: 40px;
	background-color: #57AF63;
	border-radius: 50%;
	animation: bounceX 1s infinite;
  }

  @keyframes bounceX {
	0%, 100% {
	  transform: translateX(0);
	}
	50% {
	  transform: translateX(-10px);
	}
  }

  .bouncing-ballY {
	display: inline-block;
	width: 40px;
	height: 40px;
	background-color: #17a00a;
	border-radius: 50%;
	animation: bounceY 1s infinite;
  }
  
  
  @keyframes bounceY {
	0%, 100% {
	  transform: translateY(0);
	}
	50% {
	  transform: translateY(-50px);
	}
  }

  
input.defaultCheckbox{
	 color: white; 
} 

input.defaultCheckbox::before {
	content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.66662 10.115L12.7946 3.98633L13.7379 4.92899L6.66662 12.0003L2.42395 7.75766L3.36662 6.81499L6.66662 10.115Z' fill='white'/%3E%3C/svg%3E%0A"); 
	fill: currentColor;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	top:0;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input.defaultCheckbox::before path {
	fill: currentColor; 
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

.fade-in {
	animation: fadeIn 1.2s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
  }
  